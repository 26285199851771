<template>
  <div>
    <b-card
      v-if="pageData"
      class="pages-add-wrapper"
    >

      <validation-observer
        ref="pageAdd"
        tag="form"
      >
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-center mb-2">
                <h3 class="mb-0 font-weight-normal">
                  Create Page
                </h3>
              </div>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Title"
                label-for="pages-add-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    id="pages-add-title"
                    v-model="pageData.title"
                    @input="generateSlug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Slug"
                label-for="pages-add-slug"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="slug"
                  rules="required"
                >
                  <b-form-input
                    id="pages-add-slug"
                    v-model="pageData.slug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="4"
              class="mb-2"
            >
              <b-form-group
                label="Anchor at the front"
                label-for="anchor-select"
                class="mb-2"
              >
                <v-select
                  id="anchor-select"
                  v-model="pageData.anchor"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="anchors"
                  :clearable="true"
                  :reduce="option => option.value"
                  placeholder="Select Option"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="pages-add-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="content"
                  rules="required"
                >
                  <editor
                    id="email-content"
                    ref="myEditor"
                    v-model="pageData.content"
                    :api-key="tinyAPI"
                    :init="tinyOptions"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              class="mt-50"
            >
              <b-button
                variant="primary"
                class="mr-1"
                @click="handleForm"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'admin-cms-pages'}"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted } from '@vue/composition-api'
import { tinyOptions } from '@core/mixins/tinyOptions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Editor from '@tinymce/tinymce-vue'
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/pages/pagesStoreModule'
import usePagesSetting from '@/views/admin/pages/usePagesSetting'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Editor,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions],
  data() {
    return {
      pageData: {
        title: null,
        slug: null,
        content: null,
        anchor: null,
      },
      anchors: [],
      required,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-cms-pages'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
    const {
      createPage,
      fetchPageAnchorsNames,
    } = usePagesSetting()
    return {
      createPage,
      fetchPageAnchorsNames,
    }
  },
  async created() {
    this.anchors = await this.fetchPageAnchorsNames()
  },
  methods: {
    async createNewPage() {
      await this.createPage(this.pageData)
      this.$router.push({ name: 'admin-cms-pages' })
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.pageAdd.validate().then(success => {
          if (success) {
            resolve(true)
            this.createNewPage(this.pageData)
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
    generateSlug(text) {
      if (!(text instanceof Event)) {
        this.pageData.slug = text
          .toLowerCase()
          .replace(/ /g, '-')
          .replace(/[^\w-]+/g, '')
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
